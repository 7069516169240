@use 'global' as *;
.realtor_card.realtor_card {
  display: flex;
  justify-content: center;

  img {
    height: inherit;
  }
}

.two_agents {
  div {
    &:last-child {
      div {
        margin-bottom: 0;
      }
    }
  }
}
