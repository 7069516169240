@use 'global' as *;
// community landing template styles

.container {
  margin: 0 auto 20px;
  max-width: rfs(1038px);
  width: 100%;

  @include media($medium) {
    display: flex;
    gap: 50px;
    justify-content: center;
  }
}

.side_bar {
  margin: auto;
  width: fit-content;

  @include media($medium) {
    margin: 0;
    order: -1;
    width: 315px;
  }
}

.content_container {
  flex: 100%;
  margin: 0 auto;
  width: 100%;

  @include media($small) {
    max-width: 660px;
  }

  .summary {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    text-transform: uppercase;
  }

  h2,
  h3,
  h4 {
    font-family: var(--font-family-base);
  }

  p {
    @include body-b1;
  }
}

.tabs {
  margin-bottom: 20px;

  > div {
    font-weight: bold;
    justify-content: space-between;
    text-transform: uppercase;
  }
}

.photos_container {
  > div {
    margin-left: unset;
    margin-right: unset;
  }
}

.accordion_container {
  margin-top: 50px;
}

.red_caret_theme_override {
  padding: 0;
}
