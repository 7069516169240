@use 'global' as *;
// ---------------------------------------------------------

$shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);

// ---------------------------------------------------------

@mixin default-theme {
  background-color: var(--color-white);
  box-shadow: $shadow;
  overflow: hidden;
  transition: color 0.25s ease-out;

  a {
    &:hover {
      text-decoration: none;

      .realtor_name {
        color: var(--color-red-primary);
      }
    }
  }

  .contact_link {
    a:hover {
      color: var(--color-red-primary);
    }
  }
}

// ---------------------------------------------------------

.container {
  display: flex;
  height: 100%;

  &.full_width {
    width: 100%;
  }
}

// ---------------------------------------------------------

.card {
  display: flex;
}

// ---------------------------------------------------------

.realtor_name {
  @include font-size(24px, 28px);

  color: var(--color-black);
  font-family: var(--font-family-base);
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

.realtor_img {
  display: block;
  height: 100%;
  position: relative;

  > div {
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.realtor_languages {
  @include font-size(12px, 20px);
}

.realtor_licenses {
  @include font-size(12px, 20px);

  font-weight: 600;
}

.realtor_subtitle {
  @include font-size(10px, 18px);

  color: var(--color-gray-4);
  font-family: var(--font-family-medium);
  letter-spacing: 0.5px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.realtor_team {
  @include font-size(12px, 20px);

  font-family: var(--font-family-medium);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.bg_container {
  display: none;
}

.contact_content {
  @include font-size(12px, 18px);

  align-self: flex-end;
  background-color: var(--color-gray-1);
  color: var(--color-gray-5);
  display: flex;
  font-family: var(--font-family-medium);
  grid-area: 2 / 2 / 3 / 5;
  max-height: 40px;
  min-width: 0;
  padding: 6px 6px 7px 0;

  @media (max-width: $small) {
    grid-area: 2 / 1 / 3 / 5;
    justify-content: center;
  }
}

.contact_link {
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 105px;

  a {
    align-items: center;
    color: var(--color-gray-5);
    display: flex;
    padding: rfs(0 16px);

    svg {
      margin-right: 6px;
    }
  }

  button {
    align-items: center;
    color: var(--color-gray-5);
    display: flex;
    font-family: var(--font-family-medium);
    font-size: 12px;
    padding: rfs(0 16px);

    svg {
      margin-right: 6px;
    }
  }

  button:hover {
    text-decoration: underline;
  }
}

.phone_number {
  @include font-size(14px, 18px);

  a {
    color: var(--color-black);
  }
}

.contact_link:nth-child(2) {
  border-left: 1px solid var(--color-gray-3);
  border-right: 1px solid var(--color-gray-3);
}

.realtor_card {
  // ---------------------------------------------------------
  // Vertical theme (primary)

  &_primary {
    background-color: var(--color-white);
    border-radius: 4px;
    box-shadow: $shadow;
    overflow: hidden;
    text-align: center;
    transition: color 0.25s ease-out;
    width: 315px;

    .card {
      flex-direction: column;
    }

    .bg_container {
      display: block;
      position: absolute;

      .bg_overlay {
        background-image: url('../../../public/images/stripes-rgb@3x.jpg');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 315px 80px;
        border-radius: 4px 4px 0 0;
        filter: contrast(300%);
        height: 80px;
        position: relative;
        width: 315px;

        &::after {
          background-color: rgb(29 29 29 / 50%);
          border-radius: 4px 4px 0 0;
          bottom: 0;
          box-shadow: $shadow;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    .realtor_img {
      height: auto;
      margin: rfs(20px auto 0);
      width: 110px;

      > div {
        height: 100%;
      }
    }

    .inner_content {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: rfs(10px);

      .realtor_name {
        @include font-size(24px, 29px);

        letter-spacing: -0.5px;
      }

      .realtor_subtitle {
        @include font-size(12px, 18px);

        padding-bottom: 3px;
        padding-top: 4px;
      }
    }

    &.on_listing_page {
      box-shadow: none;
      width: 100%;

      .bg_overlay {
        background-image: url('../../../public/images/stripes-rgb@3x.jpg');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 315px 80px;
        border-radius: 4px 4px 0 0;
        filter: contrast(300%);
        height: 80px;
        position: relative;
        width: 315px;

        &::after {
          background-color: rgb(29 29 29 / 50%);
          border-radius: 4px 4px 0 0;
          bottom: 0;
          box-shadow: $shadow;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .realtor_languages {
        padding-top: 2px;
      }

      .phone_number {
        @include font-size(14px, 18px);

        padding-top: 2px;
      }

      .contact_content {
        justify-content: center;
      }
    }
  }

  // ---------------------------------------------------------
  // Horizontal Small theme

  &_horizontal_small {
    @include default-theme;

    background-color: var(--color-white);
    border-radius: 4px;
    box-shadow: $shadow;
    margin-bottom: 15px;
    max-width: 300px;
    overflow: hidden;
    transition: color 0.25s ease-out;

    .realtor_img {
      position: relative;
      width: 85px;
    }

    .inner_content {
      align-self: center;
      padding: 10px;

      .realtor_name {
        padding-right: rfs(5px);
      }
    }

    .contact_content {
      a {
        padding: 0 20px;
      }
    }

    &.on_listing_page {
      box-shadow: none;
      max-width: unset;
      width: 100%;
    }

    .phone_number {
      @include font-size(14px, 18px);

      padding-top: 3px;
    }
  }

  // ---------------------------------------------------------
  // Office Detail theme

  &_office_detail {
    @include default-theme;

    box-shadow: none;
    margin-bottom: 15px;
    max-width: 100%;

    .realtor_img {
      width: 108px;

      img {
        width: 100%;
      }
    }

    .inner_content {
      padding: 0 18px;

      .realtor_name {
        @include font-size(26px, 30px);

        display: block;
        letter-spacing: -0.5px;
        margin-bottom: 4px;
        padding-right: rfs(20px);
      }

      .realtor_subtitle {
        @include font-size(14px, 18px);
      }
    }

    .contact_content {
      display: none;
    }
  }

  // ---------------------------------------------------------
  // Horizontal Large theme

  &_horizontal_large {
    height: 100%;

    .card {
      @include default-theme;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      height: 100%;
      max-width: 510px;
      min-height: 150px;
      width: 100%;

      @include media($medium) {
        grid-template-rows: repeat(2, 50%);
      }
    }

    .realtor_img {
      grid-area: 1 / 1 / 3 / 2;
      max-height: 180px;
      min-width: 120px;
      width: 100%;

      @include media($medium, down) {
        grid-area: auto;
      }
    }

    .inner_content {
      grid-area: 1 / 2 / 2 / 5;
      padding: rfs(10px 18px);

      h2 {
        text-align: left;
      }

      .realtor_subtitle {
        @include media($small, down) {
          font-size: 10px;
        }
      }
    }

    .contact_content {
      @include media($medium, down) {
        grid-area: 2 / 1 / 3 / 5;
        justify-content: center;
      }

      justify-content: space-evenly;

      .contact_link {
        min-width: 0;
      }
    }
  }
}
