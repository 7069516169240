@use 'global' as *;
.pagination {
  @include reset-list;

  align-items: center;
  display: flex;
  font-family: var(--font-family-medium);
  justify-content: center;
  letter-spacing: 0.5px;

  li {
    @include font-size(16px, 20px);

    display: flex;
  }

  a {
    color: var(--color-gray-accessibility);
    cursor: pointer;
    padding: 10px 3.5px;
    text-decoration: none;
  }

  svg {
    height: 15px;
    position: relative;
  }
}

// ---------------------------------------------------------

.active {
  a {
    color: var(--color-black);
  }
}
