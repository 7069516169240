@use 'global' as *;
.container {
  background-color: rgb(255 255 255 / 85%);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 13%);
  padding-bottom: rfs(25px);
  width: 315px;

  .subheader {
    display: block;
    font-size: 1rem;
    padding: rfs(15px 10px);
    text-align: center;

    button {
      color: var(--color-red-primary);
      font-family: var(--font-family-medium);
      font-size: 1rem;
      text-decoration: none;
      transition: color 0.25s ease-out;
    }

    button:hover {
      text-decoration: underline;
    }
  }

  .tour_btn {
    display: flex;
    justify-content: center;
    margin: rfs(15px 30px 0);
    text-align: center;
  }

  .question {
    @include font-size(12px, 20px);

    color: var(--color-red-primary);
    font-family: var(--font-family-medium);
    margin-bottom: rfs(24px);
    margin-top: rfs(12px);
    text-align: center;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
