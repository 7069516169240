@use 'global' as *;
.grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  margin: rfs(16px -40px -30px);
  max-width: 1200px;
  padding-bottom: 20px;
  position: relative;

  @media (max-width: $small-lowest) {
    margin: 0;
    padding-bottom: 40px;
    padding-top: 10px;
  }

  .gallery_text {
    @include font-size(15px, 24px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 20px;
    text-align: center;
  }

  .image_title {
    margin-left: 20%;
    text-align: center;
    width: 60%;

    @media (max-width: $small-lowest) {
      margin-bottom: 20px;
    }
  }

  .textbox {
    display: flex;
    flex-flow: row wrap;
  }

  .image_pagination {
    text-align: right;
    width: 20%;
  }

  .mobile_img {
    margin-bottom: 20px;
  }
}

.full_grid {
  width: 90vw;
}

.spinner {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
