@use 'global' as *;
.properties_container {
  margin-bottom: 10px;
}

.card_outer {
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
  }
}

.tabs_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @include media($x-small-highest) {
    align-items: center;
    flex-direction: row;
  }

  span {
    font-weight: bold;
    white-space: nowrap;
  }

  .tabs {
    padding-top: 15px;
    width: 100%;

    @include media($x-small-highest) {
      padding-top: unset;
      width: 70%;
    }
  }
}

.container {
  min-height: 200px;
}

.zero_state {
  @include zero-state;

  width: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
